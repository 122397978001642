@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Work+Sans:wght@300&display=swap');

body{
    background-color: #111827;
    font-family: "Poppins","sans-serif";
}
.work-sans{
    font-family: "Work Sans", "sans-serif";
}
::-webkit-scrollbar{
    width: 9px;
}
::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent,#239066);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
::-webkit-scrollbar-thumb:hover{
    background:linear-gradient(transparent,#3be6be);
}